<template>

    <div id="CalculadoraMargenBeneficio">

        <b-container fluid>
        <b-row class="d-flex justify-content-center">
        <b-col md="6">
            <b-card class="card border-shadow2">
                <b-card-text>
                    <b-row class="d-flex justify-content-center">
                        <b-col cols="auto" md="auto">

                            <b-form-group label="Costo del Producto" label-for="costo">
                                <b-input-group>
                                    <b-input-group-prepend is-text>$</b-input-group-prepend>
                                    <b-form-input id="costo" v-model="costo" type="number" min="0" step="0.01"
                                        required></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Precio de Venta" label-for="precio">
                                <b-input-group>
                                    <b-input-group-prepend is-text>$</b-input-group-prepend>
                                    <b-form-input id="precio" v-model="precio" type="number" min="0" step="0.01"
                                        required></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Margen de Beneficio" label-for="margen">
                                <b-input-group>
                                    <b-input-group-prepend is-text>%</b-input-group-prepend>
                                    <b-form-input id="margen" v-model="margen" type="number" min="0" step="0.01"
                                        required></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-button @click="calcularMargen" variant="primary" class="mt-4 btn-submit">Calcular</b-button>


                        </b-col>
                        <b-col cols="12" md="6" >
                            <p class="align-text-center thin subtitle calculator">Resultado</p>
                            <p class="align-text-center thin subtitle text-purple calculator">{{calculoText}}</p>
                            <p class="align-text-center calculator">{{calculoTextResult}}</p>

                            <!-- <b-alert variant="success" fade :show="isVisible">
                                <h4 class="alert-heading">Margen de Beneficio</h4>
                                <p>El margen de beneficio de tu producto es del <b>{{ margenBeneficio }}%</b>.</p>
                            </b-alert>
                            <b-alert variant="info" fade :show="secondIsVisible">
                                <h4 class="alert-heading">Precio de Venta</h4>
                                <p>Para obtener un margen de beneficio del <b>{{ margen }}%</b> necesitas vender tu
                                    producto en <b>${{ precioVenta }}</b>.</p>
                            </b-alert>
                            <b-alert variant="warning" fade :show="thirdIsVisible">
                                <h4 class="alert-heading">Costo del Producto</h4>
                                <p>Para obtener un margen de beneficio del <b>{{ margen }}%</b> necesitas que tu
                                    producto cueste <b>${{ costoProducto }}</b>.</p>
                            </b-alert>
                            <b-alert variant="danger" fade :show="fourthIsVisible">
                                <h4 class="alert-heading">Error</h4>
                                <p>Debes dejar un campo vacío para realizar el cálculo.</p>
                            </b-alert> -->
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
        </b-row>
            

        </b-container>
    </div>
</template>
<script>
export default {
    name: "CalculadoraMargenBeneficio",
    data() {
        return {
            isVisible: false,
            secondIsVisible: false,
            thirdIsVisible: false,
            fourthIsVisible: false,
            costo: 0,
            precio: 0,
            margen: 0,
            margenBeneficio: 0,
            precioVenta: 0,
            costoProducto: 0,
            calculoText:"",
            calculoTextResult:""

        };
    },
    methods: {
        calcularMargen() {
            this.costo = parseFloat(this.costo);
            this.precio = parseFloat(this.precio);
            this.margen = parseFloat(this.margen);
            console.log(this.costo, this.precio, this.margen);
            if (this.margen > 0 && this.costo > 0 && this.precio > 0) {
                this.fourthIsVisible = true;
                this.calculoText = "Error";
                this.calculoTextResult = "Debes dejar un campo vacío para realizar el cálculo.";

            } else if ((this.costo == 0 || this.costo=='' || isNaN(this.costo)) && this.precio > 0 && this.margen > 0) {
                console.log("entro");
                this.costoProducto = this.precio * (1 - this.margen / 100);
                this.costoProducto = this.costoProducto.toFixed(2);
                this.calculoText = "Costo del Producto";
                this.calculoTextResult = `Para obtener un margen de beneficio del ${this.margen}% necesitas que tu producto cueste $${this.costoProducto}.`;
                this.isVisible = false;
                this.secondIsVisible = !this.secondIsVisible;

            } else if ((this.precio == 0 || this.precio=='' || isNaN(this.precio)) && this.costo > 0 && this.margen > 0) {
                this.precioVenta = this.costo / (1 - this.margen / 100);
                this.precioVenta = this.precioVenta.toFixed(2);
                this.calculoText = "Precio de Venta";
                this.calculoTextResult = `Para obtener un margen de beneficio del ${this.margen}% necesitas vender tu producto en $${this.precioVenta}.`;
                this.secondIsVisible = true;
                this.fourthIsVisible = false;
            } else if ((this.margen == 0 || this.margen=='' || isNaN(this.margen)) && this.costo > 0 && this.precio > 0) {
                this.margenBeneficio = ((this.precio - this.costo) / this.precio) * 100;
                this.margenBeneficio = this.margenBeneficio.toFixed(2);
                this.calculoText = "Margen de Beneficio";
                this.calculoTextResult = `El margen de beneficio de tu producto es del ${this.margenBeneficio}%.`;
                this.isVisible = true;
                this.fourthIsVisible = false;
            } else {
                this.calculoText = "Error";
                this.calculoTextResult = "Debes dejar un campo vacío para realizar el cálculo.";
                this.fourthIsVisible = true;
            }
        }
    },
    mounted() {
        // const options = {
        //     root: null,
        //     rootMargin: '0px',
        //     threshold: 0.5 // Adjust this threshold value to trigger the visibility
        // };

        // const observer = new IntersectionObserver((entries) => {
        //     entries.forEach((entry) => {
        //         if (entry.isIntersecting) {
        //             this.isVisible = true;
        //             setTimeout(() => {
        //                 this.secondIsVisible = true;
        //             }, 300);
        //             setTimeout(() => {
        //                 this.thirdIsVisible = true;
        //             }, 600);
        //             setTimeout(() => {
        //                 this.fourthIsVisible = true;
        //             }, 900);
        //             observer.unobserve(entry.target);
        //         }
        //     });
        // }, options);

        // observer.observe(this.$el);
    },
};
</script>