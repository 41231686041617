<template>
    <div id="HomeComponent">
        <b-container fluid>
            <NavBar />
            <h1 class="align-text-center thin">Calculadora de Margen de Beneficio</h1>
            <p class="align-text-center thin subtitle">Optimiza tus Ganancias Fácilmente</p>
            <Calculatormargen_component class="mb-4" />
            <h2 class=" thin mt-4">¿Qué es el Margen de Beneficio?</h2>
            <p class=" text-p">Es la rentabilidad de un producto, servicio o negocio.</p>
            <h2 class=" thin">¿Cómo usar esta calculadora?</h2>
            <p class=" text-p">Ingresa valores en los campos según sea que requieras calcular. Si deseas saber el Margen
                de Beneficio llena los campos de Costo de Producto y Precio de Venta. Si deseas saber el Precio de Venta
                ingresa el costo del producto y el Margen de Beneficio que esperas. Si deseas saber el Costo del
                Producto ingresa el Precio de Venta y el Margen de Beneficio esperado.</p>
            <h2 class=" thin">Ventajas de calcular tu margen de beneficio</h2>
            <p class=" text-p">Calcular el margen de beneficio es una práctica esencial para cualquier negocio,
                independientemente de su tamaño o industria. Este indicador financiero no solo te ayuda a medir la
                rentabilidad de tus productos o servicios, sino que también te permite tomar decisiones estratégicas más
                informadas. A continuación, te presentamos las principales ventajas de calcular tu margen de beneficio:
            </p>
            <b-row>
                <b-col md="auto" lg="auto" sm="auto">
                    <img width="75%" alt="Margen de Beneficio"
                        src="../assets/img/homepage/chillbyte ventas.png" />
                </b-col>
                <b-col>
                    <ul class="text-p">
                        <li class="mb-4"><strong>Optimización de Precios:</strong> Conocer tu margen de beneficio te ayuda a
                            establecer
                            precios competitivos que cubran tus costos y generen ganancias. Al identificar cuánto ganas
                            por cada
                            producto o servicio vendido, puedes ajustar tus precios para maximizar la rentabilidad sin
                            comprometer la demanda.</li>
                        <li class="mb-4"><strong>Identificación de Productos Más Rentables:</strong> de esta manera podrás enfocar tus
                            esfuerzos en promoverlos o aumentar su producción</li>
                        <li class="mb-4"><strong>Mejora en la Toma de Decisiones:</strong> es esencial para lanzar nuevos productos,
                            reducir
                            costos o negociar con proveedores. Con esta métrica, tendrás una visión más clara de la
                            salud
                            financiera de tu negocio.</li>
                        <li class="mb-4"><strong>Comparación con la Competencia:</strong> Conocer tu margen de beneficio te permite
                            compararte
                            con empresas de tu sector y evaluar si estás en línea con el mercado</li>
                        <!-- Add more bullet points as needed -->
                    </ul>
                </b-col>

            </b-row>
            <b-row>
            <b-col><h3 class=" thin mb-4">Optimiza tus estrategias de precios con estos consejos</h3>
            <ul class="text-p" style="list-style-type: none;">
                <li class="mb-4"><strong>1. Conoce tus costos</strong>: Asegúrate de incluir costos directos e indirectos para
                    establecer un precio rentable.</li>
                <li class="mb-4"><strong>2. Estudia a tu competencia</strong>: Analiza los precios del mercado y ajusta los tuyos
                    para destacar sin perder
                    competitividad.</li>
                <li class="mb-4"><strong>3. Segmenta tu mercado</strong>: Ofrece precios diferenciados según las necesidades y
                    disposición a pagar de tus
                    clientes.</li>
                <li class="mb-4"><strong>4. Calcula tu margen de beneficio</strong>: Usa herramientas como una calculadora de margen
                    para garantizar que
                    obtienes ganancias adecuadas.</li>
                <li class="mb-4"><strong>5. Aplica precios psicológicos</strong>: Redondeos estratégicos (como $9.99) pueden aumentar
                    la percepción de valor.</li>
                <li class="mb-4"><strong>6. Prueba y ajusta</strong>: Experimenta con precios y monitorea su impacto en las ventas
                    para encontrar el
                    equilibrio ideal.</li>
                <li><strong>7. Ofrece descuentos estratégicos</strong>: Úsalos para mover inventarios o atraer nuevos
                    clientes sin devaluar tu
                    marca.</li>
            </ul></b-col>
            <b-col md="auto" lg="auto" sm="auto">
                <img width="100%"  alt="Margen de Beneficio"
                src="../assets/img/homepage/chillbyte estrategias.png" />
            </b-col>
            </b-row>
            
            <h3 class=" thin">Mejora la rentabilidad de tu negocio</h3>


        </b-container>
        <ContactForm />
        <FooterChillByte />
    </div>
</template>

<script>
import NavBar from "../components/Template/NavBar.vue";
import Footer from '../components/Template/Footer.vue';
import ContactForm from "@/components/Template/ContactForm.vue";
import Calculatormargen_component from "@/components/apps/calculatormargen_component.vue";

export default {
    name: "CalculadoraView",
    components: {
        NavBar,
        FooterChillByte: Footer,
        ContactForm,
        Calculatormargen_component
    },
    metaInfo: {
        title: 'Calculadora de Margen de Beneficio',
        meta: [
            {
                name: 'description',
                content: 'Calcula el margen de beneficio de tu negocio fácilmente con nuestra herramienta gratuita. Descubre cuánto ganas y optimiza tus precios.'
            }
        ]
    },
    data() {
        return {

        };
    },
    computed: {

    },
    methods: {

    }
};
</script>

<style scoped></style>