<template>
  <div id="ServiciosComp">
    <h1 class="pr-2 thin h1-size" style="text-align: right;">Nuestros <span class="text-purple">Servicios</span>.</h1>
    <p class="pr-2 thin h4-size" style="text-align: right;">En Chill Byte, ofrecemos servicios de desarrollo especializado en Oracle Netsuite, web y móvil para empresas que buscan optimizar sus procesos y alcanzar nuevos niveles de eficiencia.</p>
    <div class="w-100 m-0 d-flex justify-content-center mx-0" style="margin-right:0px !important;">
      <b-row class="w-100 d-flex justify-content-center align-items-stretch">
        <b-col sm="12" md="2" class="d-flex justify-content-center align-items-stretch">
          <div v-if="isVisible" class="bounce-in-down-enter-active">
            <b-card title="Oracle Netsuite" :img-src="require('../../assets/img/homepage/oracle netsuite.png')"
              img-alt="Image" img-top tag="article" style="max-width: 20rem" class="mb-2 border-shadow h-100">

              <b-card-text>
                Automatiza tu ERP con integraciones adaptadas a tus necesidades.
              </b-card-text>
            </b-card>
          </div>

        </b-col>
        <b-col sm="12" md="2" class="d-flex justify-content-center">
          <div v-if="isVisible" class="bounce-in-down-enter-active">
            <b-card title="Páginas Web" :img-src="require('../../assets/img/homepage/chillbyte páginas web.png')"
              img-alt="Image" img-top tag="article" style="max-width: 20rem" class="mb-2 border-shadow h-100">

              <b-card-text>
                Creamos la página web de tu negocio para que el mundo te conozca.
              </b-card-text>
            </b-card>
          </div>

        </b-col>
        <b-col sm="12" md="2" class="d-flex justify-content-center">
          <div v-if="secondIsVisible" class="bounce-in-down-enter-active">
            <b-card title="Aplicaciones móviles"
              :img-src="require('../../assets/img/homepage/chillbyte páginas web (1).png')"
              img-alt="Image" img-top tag="article" style="max-width: 20rem" class="mb-2 border-shadow h-100">
              <b-card-text>
                Apps innovadoras para potenciar la experiencia de tus usuarios
              </b-card-text>
            </b-card>
          </div>
        </b-col>
        <b-col sm="12" md="2" class="d-flex justify-content-center">
          <div v-if="thirdIsVisible" class="bounce-in-down-enter-active">

            <b-card title="Fotografía y video"
              :img-src="require('../../assets/img/homepage/chillbyte fotografia.png')"
              img-alt="Image" img-top tag="article" style="max-width: 20rem" class="mb-2 border-shadow h-100">
              <b-card-text>
                Una buena imagen es de alto valor para tu negocio.
              </b-card-text>
            </b-card>
          </div>
        </b-col>
        <b-col sm="12" md="2" class="d-flex justify-content-center">
          <div v-if="fourthIsVisible" class="bounce-in-down-enter-active">

            <b-card title="Aprendizaje para todos"
              :img-src="require('../../assets/img/homepage/chillbyte academy.png')"
              img-alt="Image" img-top tag="article" style="max-width: 20rem" class="mb-2 border-shadow h-100">
              <b-card-text>
                Cursos desde computación básica hasta programación avanzada.
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiciosComp",
  data() {
    return {
      isVisible: false,
      secondIsVisible: false,
      thirdIsVisible: false,
      fourthIsVisible: false
    };
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust this threshold value to trigger the visibility
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible = true;
          setTimeout(() => {
            this.secondIsVisible = true;
          }, 300);
          setTimeout(() => {
            this.thirdIsVisible = true;
          }, 600);
          setTimeout(() => {
            this.fourthIsVisible = true;
          }, 900);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(this.$el);
  },

};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bounce-in-down-enter-active {
  animation: bounce-in-down 0.7s;
}

@keyframes bounce-in-down {
  0% {
    transform: translateY(-100px);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  60% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }

  75% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }

  90% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(0);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}
</style>